import { SupplierType } from 'types/supplier.types';

const { REACT_APP_ENV } = process.env;

const SUPPLIERS_ID_LOCAL_INDEX: { id: number, name: string }[] = [
  // (Nadro = 26),
  { id: 26, name: 'Nadro MTY' },
  // (Fármacos Nacionales MTY = 4),
  { id: 4, name: 'Fármacos Nacionales MTY' },
  // (Marzam MTY= 1),
  { id: 1, name: 'Marzam MTY' },
  // (Almacen de drogas MTY = 3),
  { id: 3, name: 'Almacen de drogas MTY' },
  // (Almacen de drogas CDMX = 29),
  { id: 29, name: 'Almacen de drogas CDMX' },
  // (Nadro CDMX = 30),
  { id: 30, name: 'Nadro CDMX' },
  // (Marzam CDMX = 28),
  { id: 28, name: 'Marzam CDMX' },
  // Abbott a través de Marzam = 34
  { id: 34, name: 'Abbott a través de Marzam' },
  // Farmaviatris = 35
  { id: 35, name: 'Farmaviatris' },
  // Abbot = 36
  { id: 36, name: 'Abbot' },
  // PLENLIFE = 37
  { id: 37, name: 'PLENLIFE' },
  // Corne = 38
  { id: 38, name: 'Corne' },
  // Deuten = 39
  { id: 39, name: 'Deuten' },
  // PacificMed = 40
  { id: 40, name: 'PacificMed' },
  // Medtronic = 27
  { id: 27, name: 'Medtronic' },
  // Mepiel = 41
  { id: 41, name: 'Mepiel' },
  // Demsa = 42
  { id: 42, name: 'Demsa' },
  // Eternux = 43
  { id: 43, name: 'Eternux' },
  // Unimedical = 44
  { id: 44, name: 'Unimedical' },
  // Medpharma = 45
  { id: 45, name: 'Medpharma' },
  // Teriana = 46
  { id: 46, name: 'Teriana' },
  // Probiotix = 47
  { id: 47, name: 'Probiotix' },
  // PacificPharma = 48
  { id: 48, name: 'PacificPharma' },
  // Munnat = 49
  { id: 49, name: 'Munnat' },
  // Kabla = 50
  { id: 50, name: 'Kabla' },
  // Proveedora médica = 51
  { id: 51, name: 'Proveedora médica' },
  // ELEMATE = 52
  { id: 52, name: 'ELEMATE' },
  // Biosaint = 53
  { id: 53, name: 'Biosaint' },
  // MotivATOM = 54
  { id: 54, name: 'MotivATOM' },
  // Nadro hospitaria
  { id: 55, name: 'Nadro hospitaria' },
  // AEFAH = 56
  { id: 56, name: 'AEFAH' },
  // Birdman = 57
  { id: 57, name: 'Birdman' },
  // NEB = 58
  { id: 58, name: 'NEB' },
  // Biopas = 59
  { id: 59, name: 'Biopas' },
  // LIV Nutrition
  { id: 60, name: 'LIV Nutrition' },
  // MediHc = 61
  { id: 61, name: 'MediHc' },
  // Regiomed Vitau
  { id: 62, name: 'Regiomed Vitau' },
  // gera
  { id: 63, name: 'Gera' },
  // Pharmazing
  { id: 64, name: 'Pharmazing' },
  // CODEFAR
  { id: 65, name: 'CODEFAR' },
  // Proasse
  { id: 66, name: 'Proasse' },
  // Global Store
  { id: 67, name: 'Global Store' },
  // Global Store
  { id: 69, name: 'Farmacias Providencia' },
  // Farmacéuticos Tenorio
  { id: 70, name: 'Farmacéuticos Tenorio' },
];



const SUPPLIERS_ID_PROD_INDEX: { id: number, name: string }[] = [
  // (Nadro MTY= 26),
  { id: 2, name: 'Nadro MTY' },
  // (Fármacos Nacionales MTY = 4),
  { id: 4, name: 'Fármacos Nacionales MTY' },
  // (Marzam MTY = 1),
  { id: 1, name: 'Marzam MTY' },
  // (Almacen de drogas MTY = 3),
  { id: 3, name: 'Almacen de drogas MTY' },
  // (Almacen de drogas CDMX = 35),
  { id: 35, name: 'Almacen de drogas CDMX' },
  // (Nadro CDMX= 26),
  { id: 37, name: 'Nadro CDMX' },
  // (Marzam CDMX = 36),
  { id: 36, name: 'Marzam CDMX' },
  // Abbott a través de Marzam
  { id: 57, name: 'Abbott a través de Marzam' },
  // Farmaviatris
  { id: 58, name: 'Farmaviatris' },
  // Abbot = 5
  { id: 5, name: 'Abbot' },
  // PLENLIFE
  { id: 55, name: 'PLENLIFE' },
  // Corne = 34
  { id: 34, name: 'Corne' },
  // Deuten = 64
  { id: 64, name: 'Deuten' },
  // PacificMed = 59
  { id: 59, name: 'PacificMed' },
  // Medtronic = 20
  { id: 20, name: 'Medtronic' },
  // Mepiel = 62
  { id: 62, name: 'Mepiel' },
  // Demsa = 17
  { id: 17, name: 'Demsa' },
  // Eternux = 7
  { id: 7, name: 'Eternux' },
  // Unimedical = 18
  { id: 18, name: 'Unimedical' },
  // Med-Pharma = 31
  { id: 31, name: 'Med-Pharma' },
  // Teriana = 50
  { id: 50, name: 'Teriana' },
  // Probiotix = 9
  { id: 9, name: 'Probiotix' },
  // PacificPharma = 66
  { id: 66, name: 'PacificPharma' },
  // Munnat = 67
  { id: 67, name: 'Munnat' },
  // Kabla = 16
  { id: 16, name: 'Kabla' },
  // Proveedora médica = 13
  { id: 13, name: 'Proveedora médica' },
  // ELEMATE = 30
  { id: 30, name: 'ELEMATE' },
  // Biosaint = 23
  { id: 23, name: 'Biosaint' },
  // MotivATOM = 68
  { id: 68, name: 'MotivATOM' },
  // Nadro hospitaria
  { id: 51, name: 'Nadro hospitaria' },
  // AEFAH = 71
  { id: 71, name: 'AEFAH' },
  // Birdman = 8
  { id: 8, name: 'Birdman' },
  // NEB = 75
  { id: 75, name: 'NEB' },
  // Biopas = 80
  { id: 80, name: 'Biopas' },
  // LIV Nutrition
  { id: 81, name: 'LIV Nutrition' },
  // MediHc = 82
  { id: 82, name: 'MediHc' },
  // Regiomed Vitau
  { id: 85, name: 'Regiomed Vitau' },
  // A5 Express
  { id: 94, name: 'A5 Express' },
  // Drogas y Enseres
  { id: 95, name: 'Drogas y Enseres' },
  // Probemedic
  { id: 96, name: 'Probemedic' },
  // Pharmazing
  { id: 97, name: 'Pharmazing' },
  // CODEFAR
  { id: 100, name: 'CODEFAR' },
  // Proasse
  { id: 83, name: 'Proasse' },
  // Global Store
  { id: 103, name: 'Global Store' },
  // Farmacias Providencia
  { id: 104, name: 'Farmacias Providencia' },
  // Farmacéuticos Tenorio
  { id: 106, name: 'Farmacéuticos Tenorio' },
];

// to-do: migrate to consume suppliers using and api endpoint
export const suppliersIdSelect =
  REACT_APP_ENV === 'local' || REACT_APP_ENV === 'development'
    ? SUPPLIERS_ID_LOCAL_INDEX.map((supplier) => supplier.id)
    : SUPPLIERS_ID_PROD_INDEX.map((supplier) => supplier.id)

// this sets an initial state for suppliers info in src/context/PurchaseCart.tsx
export const initialSuppliers: SupplierType[] =
  REACT_APP_ENV === 'local' || REACT_APP_ENV === 'development'
    ? SUPPLIERS_ID_LOCAL_INDEX.map((supplier) => {
      return {
        id: supplier.id,
        name: supplier.name,
        total: '',
        availableCredit: '0',
        totalUnits: 0,
        details: [],
      }
    })
    : SUPPLIERS_ID_PROD_INDEX.map((supplier) => {
      return {
        id: supplier.id,
        name: supplier.name,
        total: '',
        availableCredit: '0',
        totalUnits: 0,
        details: [],
      }
    })
